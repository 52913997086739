<template lang="pug">
  .counts
    count-item(hideTooltip)
      v-tooltip(right :data-test="DATA_TEST.UNPAID")
        template(v-slot:activator="{ on }")
          span(v-on="on")
            template {{ item.candidates_course_not_paid_count }} /
        span Unpaid
      v-tooltip(right :data-test="DATA_TEST.CANDIDATES_COUNT")
        template(v-slot:activator="{ on }")
          span(v-on="on")
            template {{ item.will_come_candidates_count }}
        span Course list

    count-item(tooltip="Won't come" right :classes="['green--text']" :data-test="DATA_TEST.NOT_COME") {{ item.will_not_come_candidates_count }}
    count-item(tooltip="Slots" :classes="['count-item_slots']" :data-test="DATA_TEST.SLOTS") {{ item.slotsCount }}

    v-tooltip(top v-if="false")
</template>

<script>
import { CM_DATA_TEST } from '../core/cm-const'
import CountItem from "./CMCourseCountItem.vue"

export default {
  props: {
    item: {
      Object,
      required: true,
    },
  },

  data: () => ({
    DATA_TEST: CM_DATA_TEST.COUNTS
  }),

  components: {
    countItem: CountItem
  }
}
</script>

<style lang="scss" scoped>
.counts {
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  position: relative;

  &__item {
    padding: 4px 0;
    width: 25%;
    text-align: center;
    color: $label-color;
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: unset;
    }
  }
}
</style>
